import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { useState } from 'react';
import { useInterval } from 'react-use';

import { Slides } from '@/components/blocks/WorkRemotely/types/promo';

import cn from './style.module.sass';

type SliderProps = {
    slides: Slides;
    durationTimeout?: number;
};

const getDiff = (a: number, b: number) => ((a % b) + b) % b;

function Slider({ slides, durationTimeout = 2000 }: SliderProps) {
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const slidesLength = slides.length;

    useInterval(() => {
        setCurrentSlide(oldCurrentSlide => (oldCurrentSlide + 1) % slidesLength);
    }, durationTimeout);

    return (
        <div className={cn.slider}>
            {slides.map(({ src, rotate }, index) => {
                const diff = getDiff(index - currentSlide, slidesLength);
                const zIndex = slidesLength - diff;

                return (
                    <motion.div
                        className={cn.slider__slide}
                        key={src}
                        initial={false}
                        style={{
                            rotate,
                            translateX: '-50%',
                            translateY: '-50%',
                            zIndex: diff === slidesLength - 1 ? 1 : zIndex,
                        }}
                        transition={{
                            duration: 0.5,
                        }}
                    >
                        <Image
                            className={cn.slider__image}
                            src={src}
                            alt=""
                            width={355}
                            height={355}
                            aria-hidden="true"
                        />
                    </motion.div>
                );
            })}
        </div>
    );
}

export default Slider;
