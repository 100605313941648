import React from 'react';

import { Icon } from '@/components/blocks/WorkRemotely/types/icon';

function Burger({ fill = 'none' }: Icon) {
    return (
        <svg width="37" height="22" viewBox="0 0 37 22" fill={fill}>
            <rect x="0.472656" width="36" height="2" />
            <rect x="0.472656" y="10" width="36" height="2" />
            <rect x="0.472656" y="20" width="36" height="2" />
        </svg>
    );
}

export default Burger;
