import {
    DO_WE_OFFER_KEY,
    SALES_OFFICES_KEY,
    WORK_REMOTELY_KEY,
} from '@/components/blocks/WorkRemotely/helpers/constants/menu';

export const menu = [
    { title: 'Что мы предлагаем', id: DO_WE_OFFER_KEY },
    { title: 'Работа в офисах продаж', id: SALES_OFFICES_KEY },
    { title: 'Работа из дома', id: WORK_REMOTELY_KEY },
];
