export const advantages = {
    title: 'Что мы предлагаем',
    list: [
        {
            icon: '/img-next/svg/hr-landing/advantages/rectangles.svg',
            title: 'Стабильность и&nbsp;уверенность в&nbsp;завтрашнем дне',
        },
        {
            icon: '/img-next/svg/hr-landing/advantages/wallet.svg',
            title: 'Официальный доход: оклад и&nbsp;премия по&nbsp;результатам',
        },
        {
            icon: '/img-next/svg/hr-landing/advantages/code.svg',
            title: 'Возможность карьерного роста уже через 4 месяца',
        },
        {
            icon: '/img-next/svg/hr-landing/advantages/clock.svg',
            title: 'Удобные графики, без задержек на&nbsp;работе',
        },
        {
            icon: '/img-next/svg/hr-landing/advantages/a-cap.svg',
            title: 'Обучаем с&nbsp;&laquo;0&raquo; и&nbsp;наставник рядом',
        },
        {
            icon: '/img-next/svg/hr-landing/advantages/face.svg',
            title: 'Расширенный ДМС, корпоративные скидки',
        },
    ],
};
