import React from 'react';

import OffersList from '@/components/blocks/WorkRemotely/core/Offers/List';
import { Offer } from '@/components/blocks/WorkRemotely/types/offers';

type OffersProps = {
    offers: Offer[];
};

function Offers({ offers }: OffersProps) {
    return <OffersList offers={offers} />;
}

export default Offers;
