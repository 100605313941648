import React from 'react';

import { Icon } from '@/components/blocks/WorkRemotely/types/icon';

function Cross({ fill = 'none', stroke = 'none' }: Icon) {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill={fill} stroke={stroke}>
            <path d="m2.602 2.6 20.8 20.8m-20.8 0 20.8-20.8" strokeWidth="1.4" />
        </svg>
    );
}

export default Cross;
