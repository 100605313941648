import { isSelect } from '@/components/blocks/WorkRemotely/types/type';

export const prepareFormData = (data: Record<string, unknown>): Record<string, unknown> => {
    const arrayed = Object.entries(data);

    const preparedData: [string, unknown][] = arrayed.map(([key, value]) => {
        if (isSelect(value)) {
            return [key, value.label];
        }

        return [key, value];
    });

    return Object.fromEntries(preparedData);
};
