import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

function Logo() {
    return (
        <div className={clsx(cn.footer__item, cn.footer__item_logo)}>
            <a href="/">
                <CustomImage src="/img-next/svg/hr-landing/logo-footer.svg" alt="Логотип" width="97" height="50" />
            </a>
        </div>
    );
}

export default Logo;
