import React from 'react';

import OffersCard from '@/components/blocks/WorkRemotely/core/Offers/Card';
import { Offers } from '@/components/blocks/WorkRemotely/types/offers';

import cn from './style.module.sass';

type OffersListProps = {
    offers: Offers;
};

function OffersList({ offers }: OffersListProps) {
    if (offers.length === 0) return null;

    return (
        <div className={cn.offersList}>
            {offers.map(({ id, icon, title, list, buttonValue }) => (
                <OffersCard
                    icon={icon}
                    title={title}
                    list={list}
                    buttonValue={buttonValue}
                    id={id}
                    key={title}
                    isNewDesign
                />
            ))}
        </div>
    );
}

export default OffersList;
