import React, { ReactNode } from 'react';

import cn from './style.module.sass';

type ContainerProps = {
    children: ReactNode;
    id?: string;
};

function Container({ id, children }: ContainerProps) {
    return (
        <div className={cn.container} id={id}>
            {children}
        </div>
    );
}

export default Container;
