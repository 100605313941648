import React from 'react';

import { Icon } from '@/components/blocks/WorkRemotely/types/icon';

function Polygon({ fill = 'none' }: Icon) {
    return (
        <svg width="16" height="17" fill={fill}>
            <path d="m8 .146 1.99 3.196 3.667-.852-.853 3.667L16 8.147l-3.196 1.99.853 3.666-3.667-.852L8 16.146l-1.99-3.195-3.667.852.853-3.667L0 8.146l3.196-1.99-.853-3.666 3.667.852L8 .146Z" />
        </svg>
    );
}

export default Polygon;
