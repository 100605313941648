import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import HRButton from '@/components/blocks/HRLanding/HRButton';
import InnerList from '@/components/blocks/WorkRemotely/core/Offers/InnerList';
import { APPLY_FOR_JOB_KEY } from '@/components/blocks/WorkRemotely/helpers/constants/menu';
import { Offer } from '@/components/blocks/WorkRemotely/types/offers';
import { ScrollLink } from '@/components/UI/ScrollLink';

import cn from './style.module.sass';

function OffersCard({ id, icon, title, list, buttonValue, isNewDesign }: Offer) {
    return (
        <div className={cn.offersCard} id={id}>
            <Image className={cn.offersCard__image} src={icon} alt="" width={400} height={400} aria-hidden="true" />
            <div className={cn.offersCard__info}>
                <div className={cn.offersCard__title} dangerouslySetInnerHTML={{ __html: title }} />
                <div className={cn.offersCardList}>
                    <InnerList list={list} />
                </div>
                <ScrollLink
                    to={APPLY_FOR_JOB_KEY}
                    offset={-90}
                    activeClass="menuLinkActive"
                    className="productPanelMenuLink"
                >
                    <HRButton
                        isDark
                        customClass={clsx(cn.offersCard__button, isNewDesign && cn.isNewDesign)}
                        type="button"
                        label={buttonValue}
                    />
                </ScrollLink>
            </div>
        </div>
    );
}

export default OffersCard;
