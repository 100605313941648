import React from 'react';

import HRButton from '@/components/blocks/HRLanding/HRButton';
import Slider from '@/components/blocks/WorkRemotely/core/Slider';
import { APPLY_FOR_JOB_KEY } from '@/components/blocks/WorkRemotely/helpers/constants/menu';
import { Promo as PromoProps } from '@/components/blocks/WorkRemotely/types/promo';
import { ScrollLink } from '@/components/UI/ScrollLink';

import cn from './style.module.sass';

function Promo({ title, buttonValue, slides }: PromoProps) {
    return (
        <div className={cn.promo}>
            <div className={cn.promo__info}>
                <div className={cn.promo__title} dangerouslySetInnerHTML={{ __html: title }} />
                <ScrollLink
                    to={APPLY_FOR_JOB_KEY}
                    offset={-90}
                    activeClass="menuLinkActive"
                    className="productPanelMenuLink"
                >
                    <HRButton isBg customClass={cn.promo__button} type="button" label={buttonValue} isNewDesign />
                </ScrollLink>
            </div>
            <div className={cn.promo__slider}>
                <Slider slides={slides} />
            </div>
        </div>
    );
}

export default Promo;
