import React from 'react';

import { Advantages as AdvantagesProps } from '@/components/blocks/WorkRemotely/types/advantages';

import AdvantagesList from './List';
import cn from './style.module.sass';

function Advantages({ title, list }: AdvantagesProps) {
    return (
        <div className={cn.advantages}>
            <div className={cn.advantages__title}>{title}</div>
            <AdvantagesList list={list} />
        </div>
    );
}

export default Advantages;
