import React from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

import CustomSelect from '@/components/UI/CustomSelect';
import { ISelectElement } from '@/types/select';

interface IProps {
    control: Control;
    name?: string;
    placeholder?: string;
    [name: string]: unknown;
    customClass?: string;
    defaultValue?: ISelectElement<string>;
}

const ControlledVacancySelect: React.FC<IProps> = ({
    control,
    name = 'vacancy',
    placeholder = 'Вакансия',
    customClass,
    ...rest
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field }) => (
            <CustomSelect
                {...field}
                {...rest}
                options={[
                    {
                        value: 'remote',
                        label: 'Работа из дома ',
                    },
                    {
                        value: 'office',
                        label: 'Работа в офисах продаж',
                    },
                ]}
                placeholder={placeholder}
                customClass={customClass}
                isSearchable={false}
            />
        )}
    />
);

export default ControlledVacancySelect;
