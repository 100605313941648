import React from 'react';

import { Advantages as AdvantagesType } from '@/components/blocks/WorkRemotely/types/advantages';

import AdvantagesCard from '../Card';
import cn from './style.module.sass';

type AdvantagesListProps = {
    list: AdvantagesType['list'];
};

function AdvantagesList({ list }: AdvantagesListProps) {
    if (list.length === 0) return null;

    return (
        <div className={cn.advantagesList}>
            {list.map(({ icon, title }) => (
                <div className={cn.advantagesList__item} key={title}>
                    <AdvantagesCard icon={icon} title={title} />
                </div>
            ))}
        </div>
    );
}

export default AdvantagesList;
