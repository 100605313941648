import Image from 'next/image';
import React from 'react';

import Advantages from '@/components/blocks/WorkRemotely/core/Advantages';
import Container from '@/components/blocks/WorkRemotely/core/Container';
import Footer from '@/components/blocks/WorkRemotely/core/Footer';
import Form from '@/components/blocks/WorkRemotely/core/Form';
import Header from '@/components/blocks/WorkRemotely/core/Header';
import Offers from '@/components/blocks/WorkRemotely/core/Offers';
import Promo from '@/components/blocks/WorkRemotely/core/Promo';
import { advantages } from '@/components/blocks/WorkRemotely/data/advantages';
import { hrGtag } from '@/components/blocks/WorkRemotely/data/hrGtag';
import { menu } from '@/components/blocks/WorkRemotely/data/menu';
import { offers } from '@/components/blocks/WorkRemotely/data/offers';
import { promo } from '@/components/blocks/WorkRemotely/data/promo';
import { APPLY_FOR_JOB_KEY, DO_WE_OFFER_KEY } from '@/components/blocks/WorkRemotely/helpers/constants/menu';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';

import cn from './style.module.sass';

export interface ISalesHRLanding {
    _template?: 'workRemotelyLanding';
}

function WorkRemotelyLanding({ _template }: ISalesHRLanding) {
    return (
        <div className={cn.wrapper}>
            <Container>
                <Header menu={menu} />
            </Container>
            <Container>
                <div className={cn.starsAnimated}>
                    <Image
                        src="/img-next/svg/star.svg"
                        alt="первая звездочка"
                        width={27}
                        height={27}
                        className={cn.firstStar}
                    />
                    <Image
                        src="/img-next/svg/star.svg"
                        alt="вторая звездочка"
                        width={47}
                        height={47}
                        className={cn.secondStar}
                    />
                    <Image
                        src="/img-next/svg/star.svg"
                        alt="третья звездочка"
                        width={32}
                        height={32}
                        className={cn.thirdStar}
                    />
                </div>
                <Promo title={promo.title} buttonValue={promo.buttonValue} slides={promo.slides} />
            </Container>
            <Container id={DO_WE_OFFER_KEY}>
                <Advantages title={advantages.title} list={advantages.list} />
            </Container>
            <Container>
                <Offers offers={offers} />
            </Container>
            <Container id={APPLY_FOR_JOB_KEY}>
                <Form
                    title="Откликнуться на вакансию"
                    buttonSubmitValue="Отправить отклик"
                    _template="salesHRLandingForm"
                    hrGtag={hrGtag}
                />
            </Container>
            <Footer menu={menu} />
        </div>
    );
}

export default withBlockLinks(WorkRemotelyLanding);
