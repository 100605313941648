export const promo = {
    title: 'Почта Банк – <br/> новые возможности',
    buttonValue: 'Хочу в команду',
    slides: [
        {
            src: '/img-next/png/hr-landing/promo/promo-6.png',
            rotate: 0,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-7.png',
            rotate: 1.44,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-8.png',
            rotate: 0,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-9.png',
            rotate: -3.87,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-10.png',
            rotate: 3.11,
        },
    ],
};
