import React from 'react';

import Ellipse from '@/components/blocks/WorkRemotely/core/Icons/Ellipse';
import FivePointedStar from '@/components/blocks/WorkRemotely/core/Icons/FivePointedStar';
import Polygon from '@/components/blocks/WorkRemotely/core/Icons/Polygon';
import Star from '@/components/blocks/WorkRemotely/core/Icons/Star';
import { SALES_OFFICES_KEY, WORK_REMOTELY_KEY } from '@/components/blocks/WorkRemotely/helpers/constants/menu';

export const offers = [
    {
        id: SALES_OFFICES_KEY,
        icon: '/img-next/jpg/hr-landing/offers/offer-1.jpg',
        title: 'Работа в&nbsp;офисах продаж',
        buttonValue: 'Хочу в команду',
        list: {
            title: 'Вы будете:',
            items: [
                {
                    icon: <Ellipse fill="#312783" />,
                    name: 'Работать с&nbsp;клиентами в&nbsp;офисах продаж или на&nbsp;территории магазина-партнера',
                },
                {
                    icon: <Polygon fill="#312783" />,
                    name: 'Консультировать клиентов и&nbsp;подбирать продукты банка',
                },
                {
                    icon: <Star fill="#312783" />,
                    name: 'Оформлять необходимые документы',
                },
                {
                    icon: <FivePointedStar fill="#312783" />,
                    name: 'А&nbsp;еще, мы&nbsp;не&nbsp;работаем с&nbsp;наличными деньгами',
                },
            ],
        },
    },
    {
        id: WORK_REMOTELY_KEY,
        icon: '/img-next/jpg/hr-landing/offers/offer-3.jpg',
        title: 'Работа из&nbsp;дома',
        buttonValue: 'Хочу в команду',
        list: {
            title: 'Вы будете:',
            items: [
                {
                    icon: <Star fill="#CA3864" />,
                    name: 'Принимать звонки от&nbsp;клиентов',
                },
                {
                    icon: <Polygon fill="#CA3864" />,
                    name: 'Помогать в&nbsp;решении вопросов',
                },
            ],
        },
    },
];
