import React from 'react';

import CustomImage from '@/components/CustomImage';
import { ISocialItem } from '@/types/footer';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

type SocialsProps = {
    socials: Array<ISocialItem>;
};

function Socials({ socials }: SocialsProps) {
    return (
        <div className={cn.socials}>
            {socials?.map(({ link, name, image, gtag }) => (
                <a
                    href={link}
                    target="_blank"
                    className={cn.socials__link}
                    rel="noopener noreferrer"
                    onClick={() => {
                        event(gtag);
                    }}
                    key={link}
                >
                    <CustomImage src={image} alt={name} width={39} height={39} />
                </a>
            ))}
        </div>
    );
}

export default Socials;
