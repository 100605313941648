import React from 'react';

import { Icon } from '@/components/blocks/WorkRemotely/types/icon';

function Ellipse({ fill = 'none' }: Icon) {
    return (
        <svg width="12" height="12" fill={fill}>
            <circle cx="6" cy="6.146" r="6" />
        </svg>
    );
}

export default Ellipse;
