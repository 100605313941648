import React from 'react';

import { Icon } from '@/components/blocks/WorkRemotely/types/icon';

function FivePointedStar({ fill = 'none' }: Icon) {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill={fill}>
            <path d="M8 0.146362L10.163 5.16918L15.6085 5.67423L11.4999 9.28354L12.7023 14.6185L8 11.8264L3.29772 14.6185L4.50011 9.28354L0.391548 5.67423L5.83695 5.16918L8 0.146362Z" />
        </svg>
    );
}

export default FivePointedStar;
